import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const PreviewCompatibleImage = ({image, className, ...props}) => {
  if (!!image && !!image.childImageSharp) {
    let {alt = "", childImageSharp} = image
    return (
      <Img {...props} fluid={childImageSharp.fluid} alt={alt} className={className}/>
    )
  } else if (!!image && typeof image === "string") {
    return (
      <div className={className}>
        <img {...props} src={image} alt="" style={{"maxWidth": "100%"}}/>
      </div>
    )
  } else {
    return null
  }
}

PreviewCompatibleImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
}

export default PreviewCompatibleImage
