import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import Content, { HTMLContent } from "../components/Content"
import Heading from "../components/Heading/Heading"
import Layout from "../components/Layout/Layout"
import PageDownControl from "../components/PageDownControl/PageDownControl"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import Section from "../components/Section/Section"
import Segment from "../components/Segment/Segment"
import SubscribeForm from "../components/MailChimp/SubscribeForm"
import useSiteMetadata from "../components/SiteMetadata"


export const IndexPageTemplate = ({
  contentComponent,
  title,
  subtitle,
  image,
  subscribeCaption,
  sections,
  helmet,
}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      {helmet || ""}
      <Section modifiers={["title"]} image={image}>
        <Segment>
          <Heading level={1} modifiers={["hero"]}>{title}</Heading>
          <Heading level={0} modifiers={["hero-subtitle"]}>{subtitle}</Heading>
          <SubscribeForm caption={subscribeCaption} stacked={false} centred={true}/>
        </Segment>
        <Segment>
          <PageDownControl pageDownHash="section-1"/>
        </Segment>
      </Section>
      {
        sections.map((section, index) => (
          <Section id={`section-${index+1}`} key={index} modifiers={["side-figure"]}>
            <Segment contentModifiers={["half-text"]}>
              <PageContent className="cms-content cms-content--section" content={section.content}/>
              {section.hasSubscribeForm ? <SubscribeForm stacked={true}/> : null}
            </Segment>
            <Segment contentModifiers={["half-image"]}>
              <PreviewCompatibleImage image={section.image} className="image-wrapper--full-height"/>
            </Segment>
          </Section>
        ))
      }
    </div>
  )
}

IndexPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subscribeCaption: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  helmet: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data
  const { siteUrl } = useSiteMetadata()

  let ogImage = null
  if (page.frontmatter.ogImage){
    ogImage = page.frontmatter.ogImage.childImageSharp.fixed
  }

  return (
    <Layout>
      <IndexPageTemplate
        contentComponent={HTMLContent}
        title="Trusty Canary"
        subtitle={page.frontmatter.subtitle}
        image={page.frontmatter.image}
        subscribeCaption={page.frontmatter.subscribeCaption}
        sections={page.fields.sections}
        helmet={
          <Helmet>
            <title>{page.frontmatter.title}</title>
            <meta
              name="description"
              content={`${page.frontmatter.description}`}
            />
            <meta
              property="og:description"
              content={`${page.frontmatter.description}`}
            />
            {ogImage ? <meta property="og:image" content={`${siteUrl}${ogImage.src}`}/> : ""}
            {ogImage ? <meta property="og:image:height" content={ogImage.height}/> : ""}
            {ogImage ? <meta property="og:image:width" content={ogImage.width}/> : ""}
            <meta property="og:url" content={`${siteUrl}/`}/>
          </Helmet>
        }
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        description
        subscribeCaption
        image {
          childImageSharp {
            fluid(
              maxWidth: 2048,
              quality: 100,
              duotone: {
                highlight: "#b0a5ab",
                shadow: "#000004"
              }
            ) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        ogImage: image {
          childImageSharp {
            fixed(
              width: 1200,
              quality: 100
            ) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      fields {
        slug
        sections {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          hasSubscribeForm
          content
        }
      }
    }
  }
`
